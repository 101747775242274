import { getApi, postApi } from "@/utils/http.js";
// 发送短信验证码
export const sendValidateCode = (mobile, num) =>
  postApi(`/admin/validateCode/sendValidateCode?mobile=${mobile}&num=${num}`);

// 短信登录
export const loginByValidateCode = (mobile, mobileCode, num) =>
  postApi(
    `/admin/user/loginByValidateCode?mobile=${mobile}&mobileCode=${mobileCode}&num=${num}`
  );
// 判断是否是新用户
export const checkMobileExists = (mobile) =>
  postApi(`/admin/user/checkMobileExists?mobile=${mobile}`);
// 设置密码
export const setPassword = (mobile, password) =>
  postApi(`/admin/user/setPassword?mobile=${mobile}&password=${password}`);
// 设置昵称
export const modifyNickname = (mobile, nickname) =>
  postApi(`/admin/user/modifyNickname?mobile=${mobile}&nickname=${nickname}`);
// 上传头像
export const uploadHeadimg = (headimgurl, mobile) =>
  postApi(
    `/admin/user/uploadHeadimg?headimgurl=${headimgurl}&mobile=${mobile}`
  );
// 上传图片(文件)到阿里云oss
export const fileUpload = (data) => postApi(`/admin/user/newfileUpload`, data);

// 密码登录
export const loginByPassword = (mobile, password) =>
  postApi(`/admin/user/loginByPassword?mobile=${mobile}&password=${password}`);

// 修改密码
export const modifyPassword = (mobile, password) =>
  postApi(`/admin/user/modifyPassword?mobile=${mobile}&password=${password}`);

// 忘记密码
export const forgotPassword = (mobile, mobileCode, num, password) =>
  postApi(
    `/admin/user/forgotPassword?mobile=${mobile}&mobileCode=${mobileCode}&num=${num}&password=${password}`
  );

// 用户采集

export const infoCollection = (info, mobile, qid) =>
  postApi(
    `/admin/user/infoCollection?info=${info}&mobile=${mobile}&qid=${qid}`
  );

// 获取用户当前信息
export const findByMobile = (mobile) =>
  postApi(`/admin/user/findByMobile?mobile=${mobile}`);

// 微信扫码登录

export const wxLoginUrl = () => getApi("/admin/user/wxLoginUrl");

// 微信回调地址

export const wxCallBack = (code) =>
  postApi(`/admin/user/callBack?code=${code}`);

// 微信获取二维码
export const getQRCode = () => getApi("/admin/user/getQRCode");

/**
 * 微信二维码检查登录状态
 * @param ${String} ticket
 */
// export const checkLogin = (data) => postApi('/admin/user/checkLogin',data)

/**
 * 扫码关注公众号登录
 * @param ${String} ticket
 */
export const wXFOALogin = (ticket) =>
  postApi(`/admin/user/wXFOALogin?ticket=${ticket}`);

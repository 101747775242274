import axios from "axios"; //全局的属性
// import { getLocalStor } from "@/utils/index";
// axios.create创建一个新的请求实例instance
//  instance用法跟axios的用法一样可以使用instance（{}）。instance.get（） instance,post()
// axios.defaults.headers.["Content-Type"] = "application/json";

const instance = axios.create({
  baseURL: "/api",
  // baseURL:  process.env.VUE_APP_URL,
  timeout: 25000,
  headers: {
    "Content-Type": "multipart/form-data", // 设置请求的Content-Type为'multipart/form-data'
    device: "web",
  },
});

// 请求拦截器:在请求之前的数据处理,比如在请求头添加token，所有的请求都会经过请求拦截器
// 请求成功执行回调函数1，请求失败执行回调函数2
instance.interceptors.request.use(
  (config) => {
    let userObj = JSON.parse(localStorage.getItem("userLogin"));
    let wxLogin = JSON.parse(localStorage.getItem("wxLogin"));
    if (userObj) {
      config.headers.Authorization = userObj.token;
      config.headers.id = userObj.user_id;
    }
    if (wxLogin) {
      config.headers.unionid = wxLogin.unionid;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
// 响应拦截器:在请求响应之后对数据处理，比如:登录失败、请求数据失败的处理
// 响应成功执行回调函数1，请求失败执行回调函数2

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const callApi = (method = "GET", url, data = {}) => {
  return instance({
    method,
    url,
    params: method === "GET" ? data : {},
    data: method === "POST" ? data : {},
  });
};

// 封装一个请求函数
export const getApi = (url, data) => callApi("GET", url, data);
export const postApi = (url, data) => callApi("POST", url, data);
